// t = current time
// b = start value
// c = change in value
// d = duration
const easeInOutQuad = function(t, b, c, d) {
  t /= d / 2
  if (t < 1) return (c / 2) * t * t + b
  t--
  return (-c / 2) * (t * (t - 2) - 1) + b
}

export const scrollToX = (el, to, duration) => {
  const start = el.scrollLeft
  const change = to - start
  let currentTime = 0
  const increment = 10

  const animateScroll = id => {
    currentTime += increment
    const val = easeInOutQuad(currentTime, start, change, duration)
    el.scrollLeft = val
    currentTime < duration ? window.requestAnimationFrame(animateScroll) : id && window.cancelAnimationFrame(id)
  }

  animateScroll(null)
}

export const scrollToY = (el, to, duration) => {
  const start = el.scrollTop
  const change = to - start
  let currentTime = 0
  const increment = 10

  const animateScroll = id => {
    currentTime += increment
    const val = easeInOutQuad(currentTime, start, change, duration)
    el.scrollTop = val
    currentTime < duration ? window.requestAnimationFrame(animateScroll) : id && window.cancelAnimationFrame(id)
  }

  animateScroll(null)
}

export const scrollToWindowY = (to, duration) => {
  const element = document.scrollingElement
  const start = (element && element.scrollTop) || window.scrollY
  const change = to - start
  let currentTime = 0
  const increment = 10

  const animateScroll = id => {
    currentTime += increment
    const val = easeInOutQuad(currentTime, start, change, duration)
    window.scrollTo(0, val)
    currentTime < duration ? window.requestAnimationFrame(animateScroll) : id && window.cancelAnimationFrame(id)
  }

  animateScroll(null)
}
