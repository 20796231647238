import React from 'react'

import Layout from 'layouts'

import SEO from 'components/seo'
import Hero from './components/hero'
import Mosaic from './components/mosaic'
import OurValues from './components/our-values'
import OurCulture from './components/our-culture'
import Jobs from './components/jobs'

const CareersPage = ({
  data: {
    prismicCareers: {
      data: {
        page_meta_title,
        page_meta_description,
        page_meta_thumbnail,
        hero_title,
        hero_description,
        cursor_text,
        mosaic_images,
        mosaic_images_mobile,
        values_title,
        values_text,
        values_columns,
        culture_title,
        culture_text,
        body,
        jobs_title,
        jobs,
      },
    },
  },
}) => {
  return (
    <Layout background="#f5f5f2" labCTA={false} fadeIn={true}>
      <SEO title={page_meta_title} description={page_meta_description} thumbnail={page_meta_thumbnail} />

      <Hero title={hero_title} description={hero_description} cursorText={cursor_text} />
      <Mosaic images={mosaic_images} mobileImages={mosaic_images_mobile} />
      <OurValues title={values_title} text={values_text} columns={values_columns} />
      <OurCulture title={culture_title} text={culture_text} body={body} />
      <Jobs title={jobs_title} list={jobs} />
    </Layout>
  )
}

export default CareersPage
