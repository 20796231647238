import React from 'react'
import classNames from 'classnames/bind'
import PropTypes from 'prop-types'

import { Container } from 'components/ui/layout'
import SectionText from 'components/ui/section-text'
import Slices from 'components/ui/slices'

import * as s from './OurCulture.module.css'

const cn = classNames.bind(s)

const OurCulture = ({ title, text, body }) => {
  return (
    <div>
      <Container>
        <SectionText title={title} text={text} />

        <div className={cn('slices')}>{body?.map(Slices)}</div>
      </Container>
    </div>
  )
}

OurCulture.propTypes = {
  title: PropTypes.object,
  text: PropTypes.object,
  body: PropTypes.array,
}

export default OurCulture
