// extracted by mini-css-extract-plugin
export var section = "Jobs-module--section--GmuSq";
export var container = "Jobs-module--container--ENvrw";
export var title = "Jobs-module--title--PN1lz";
export var list = "Jobs-module--list---xvHG";
export var link = "Jobs-module--link--T0fmX";
export var line = "Jobs-module--line--87Vph";
export var row = "Jobs-module--row--f-obv";
export var jobContent = "Jobs-module--jobContent--21LOr";
export var jobTitle = "Jobs-module--jobTitle--gShmo";
export var jobLocation = "Jobs-module--jobLocation--TjVL8";
export var arrowIcon = "Jobs-module--arrowIcon--OmPRs";