import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import { useCanvas } from '@14islands/r3f-scroll-rig'

import useUIContext from 'context/ui'

import { Container } from 'components/ui/layout'
import Clickable from 'components/ui/clickable'
import Cursor from 'components/ui/cursor'
import CursorBlobCTA from 'components/three/cursor-blob-cta'
import LettersAnimation from 'components/motion/letters-animation/LettersAnimation'

import { scrollToY } from 'lib/scrollTo'

import * as s from './Hero.module.css'

const cn = classNames.bind(s)

const Hero = ({ title, description, cursorText }) => {
  const isNativeCursorHidden = useUIContext(state => state.isNativeCursorHidden)
  const toggleNativeCursor = useUIContext(state => state.toggleNativeCursor)

  const containerRef = React.useRef()

  useCanvas(() => {
    if (!containerRef.current) return

    return (
      <CursorBlobCTA
        containerElement={containerRef}
        blobColor="#fff"
        bounds={{
          width: containerRef.current?.clientWidth,
          height: containerRef.current?.clientHeight,
          left: 0,
          top: 0,
          inViewport: true,
        }}
      />
    )
  }, [containerRef.current])

  const onClickLink = event => {
    const targetId = event?.currentTarget?.getAttribute('href')
    const targetElement = document?.querySelector(targetId)
    const targetOffsetY = targetElement?.offsetTop

    if (targetOffsetY) {
      scrollToY(document.documentElement, targetOffsetY, 1000)

      toggleNativeCursor(false)
    }
  }

  return (
    <div ref={containerRef}>
      <Clickable className={cn('link')} to="#jobs" onClick={onClickLink}>
        <Container
          className={cn('container')}
          onMouseMove={() => toggleNativeCursor(true)}
          onPointerEnter={() => toggleNativeCursor(true)}
          onPointerLeave={() => toggleNativeCursor(false)}
        >
          <div className={cn('wrapper')}>
            <LettersAnimation text={title?.text} className={cn('title')} />

            <div className={cn('description')}>{description?.text}</div>
          </div>

          <Cursor isEnabled={isNativeCursorHidden} text={cursorText} />
        </Container>
      </Clickable>
    </div>
  )
}

Hero.propTypes = {
  title: PropTypes.object,
  description: PropTypes.object,
  cursorText: PropTypes.string,
}

export default Hero
