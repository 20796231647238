import React, { useMemo, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Color, Vector2, MathUtils } from 'three'
import { useFrame, useThree } from '@react-three/fiber'

import { useSpring, useViewportScroll } from 'framer-motion'
import { useScrollRig } from '@14islands/r3f-scroll-rig'

import useUIContext from 'context/ui'

import useWindowFocus from 'hooks/useWindowFocus'
import { cursorApi, usePixelCursorRef, useUnitCursorRef } from 'components/ui/cursor-tracker'

import vertexShader from './shader.vert'
import fragmentShader from './shader.frag'

const SPEED = 1

const CursorBlobInner = ({ blobColor, bounds }) => {
  const isWindowInFocus = useWindowFocus()
  const cursor = usePixelCursorRef()
  const unitCursor = useUnitCursorRef()
  const { size } = useThree()
  const { requestRender, renderScissor } = useScrollRig()
  const pixelRatio = useThree(s => s.viewport.dpr)
  const { scrollY } = useViewportScroll()

  const isNativeCursorHidden = useUIContext(state => state.isNativeCursorHidden)
  const isPageTransitionActive = useUIContext(state => state.isPageTransitionActive)

  const local = useRef({ blobEffect: 0 }).current
  const mouseLocal = useRef({ x: 0, y: 0, isHovering: false, distance: 1, hoverX: 0, hoverY: 0 }).current

  const scene = useRef()
  const material = useRef()
  const mouse = useRef({ x: 0, y: 0, clientY: 0, clientX: 0, lerpX: 0, lerpY: 0 }).current

  const blobVisible = useSpring(1, { stiffness: 10, damping: 100, restDelta: 0.01, restSpeed: 0.01 })

  const calcPosition = (x, y) => {
    mouse.y = -((y - size.top) / size.height) * 2 + 1
    mouse.x = ((x - size.left) / size.width) * 2 - 1
    mouse.clientY = y
    mouse.clientX = x
  }

  const onMouseMove = ({ pixelCursor }) => {
    calcPosition(pixelCursor.x, pixelCursor.y)

    !isPageTransitionActive && requestRender()

    if (!bounds.inViewport || isPageTransitionActive) return

    // Mouse relative position over element ([-1, 1])
    mouseLocal.y = -((cursor.y - bounds.top + scrollY.get()) / bounds.height) * 2 + 1
    mouseLocal.x = ((cursor.x - bounds.left) / bounds.width) * 2 - 1

    // Distance from center of element
    const d = Math.pow(Math.sqrt(mouseLocal.x * mouseLocal.x + mouseLocal.y * mouseLocal.y), 2)
    mouseLocal.distance = MathUtils.clamp(d, 0.3, 1.0)
    mouseLocal.isHovering = mouseLocal.x > -1 && mouseLocal.x < 1 && mouseLocal.y > -1 && mouseLocal.y < 1

    if (mouseLocal.isHovering) {
      local.blobEffect = mouseLocal.distance
    }

    requestRender()
  }

  useEffect(() => blobVisible.onChange(() => requestRender()), [])

  // listen to mouse move from CursorTracker
  useEffect(() => cursorApi.subscribe(onMouseMove), [size, isPageTransitionActive])

  // TRANSITION effect
  useEffect(() => {
    if (isPageTransitionActive) {
      // hide blob (will be replace by TransitionBlob)
      blobVisible.set(0)
    } else {
      blobVisible.set(1)
    }
  }, [isPageTransitionActive])

  // dimensions
  useEffect(() => {
    material.current.uniforms.u_res.value.x = size.width
    material.current.uniforms.u_res.value.y = size.height

    material.current.uniforms.u_planeSize.value.x = bounds?.width || 1
    material.current.uniforms.u_planeSize.value.y = bounds?.height || 1
  }, [size, bounds?.width, bounds?.height])

  useEffect(() => {
    material.current.uniforms.u_pixelRatio.value = pixelRatio?.toFixed(1)
  }, [pixelRatio])

  useEffect(() => {
    requestRender()
  }, [isWindowInFocus])

  useFrame(({ gl, camera }) => {
    const uniforms = material.current.uniforms

    if (!isNativeCursorHidden) local.blobEffect = 0

    uniforms.u_blobEffect.value =
      MathUtils.lerp(uniforms.u_blobEffect.value, local.blobEffect, local.blobEffect > 0 ? 0.09 : 0.2) *
      blobVisible.get()

    if (uniforms.u_blobEffect.value < 0.001) return

    uniforms.u_time.value += 0.01 * SPEED
    uniforms.u_color.value = new Color(blobColor)

    const lerp = 0.2

    uniforms.u_mouse.value.x = MathUtils.lerp(uniforms.u_mouse.value.x, unitCursor.viewX, lerp)
    uniforms.u_mouse.value.y = MathUtils.lerp(uniforms.u_mouse.value.y, unitCursor.viewY, lerp)

    // render with a scissor to improve perf
    // this only renders a square around the cursor instead of the whole screen
    const scissorSize = bounds.width * 0.4 // size of square in px around mouse point to draw (40% of the screen width)

    mouse.lerpY = MathUtils.lerp(mouse.lerpY, mouse.clientY, lerp)
    mouse.lerpX = MathUtils.lerp(mouse.lerpX, mouse.clientX, lerp)

    const positiveYUpBottom = size.height - (mouse.lerpY + scissorSize * 0.5) // inverse Y

    renderScissor(scene.current, camera, mouse.lerpX - scissorSize * 0.5, positiveYUpBottom, scissorSize, scissorSize)

    requestRender()
  }) // draw after other scissors

  const uniforms = useMemo(() => {
    return {
      u_pixelRatio: { value: pixelRatio?.toFixed(1) },
      u_color: { value: new Color('#000') },
      u_time: { value: 0 },
      u_blobEffect: { value: 0 },
      u_res: { value: new Vector2(window.innerWidth, window.innerHeight) },
      u_mouse: { value: new Vector2(1, 1) },
      u_planeSize: { value: new Vector2(1, 1) },
    }
  }, [])

  return (
    <scene ref={scene}>
      <mesh>
        <planeBufferGeometry attach="geometry" args={[size.width, size.height, 128, 128]} />
        <shaderMaterial
          ref={material}
          attach="material"
          args={[
            {
              vertexShader,
              fragmentShader,
            },
          ]}
          uniforms={uniforms}
          transparent={true}
        />
      </mesh>
    </scene>
  )
}

CursorBlobInner.propTypes = {
  containerElement: PropTypes.object,
  blobColor: PropTypes.string,
  bounds: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
    left: PropTypes.number,
    top: PropTypes.number,
    inViewport: PropTypes.bool,
  }),
}

const CursorBlobCTA = props => {
  const isPointerPrimaryInput = useUIContext(s => s.isPointerPrimaryInput)
  const getBrowserType = useUIContext(s => s.getBrowserType)

  return isPointerPrimaryInput && getBrowserType() !== 'MS Edge' ? <CursorBlobInner {...props} /> : null
}

export default CursorBlobCTA
