import React from 'react'
import classNames from 'classnames/bind'
import PropTypes from 'prop-types'
import { useScrollRig } from '@14islands/r3f-scroll-rig'
import { RichText } from 'prismic'

import useUIContext from 'context/ui'

import { Container } from 'components/ui/layout'
import TransitionLink from 'gatsby-plugin-transition-link'
import ViewportEnterEffect from 'components/motion/viewport-enter-effect'

import shouldNavigate from 'lib/shouldNavigate'
import requestIdleCallback from 'lib/requestIdleCallback'

import ArrowIcon from 'assets/svg/arrow.inline.svg'

import * as s from './Jobs.module.css'

const cn = classNames.bind(s)

const Jobs = ({ title, list }) => {
  const startPageTransition = useUIContext(state => state.startPageTransition)
  const setHideHeader = useUIContext(s => s.setHideHeader)
  const { isCanvasAvailable } = useScrollRig()

  return (
    <section className={cn('section')} id="jobs">
      <Container className={cn('container')}>
        <h2 className={cn('title')}>{title?.text}</h2>

        <ul className={cn('list')}>
          {list?.map(({ job }, index) => {
            if (!job?.document?.data) return null

            const {
              uid,
              data: { title: jobTitle, location, blob_color, background_color },
            } = job?.document || {}

            return (
              <li key={index}>
                <TransitionLink
                  className={cn('link')}
                  to={`/careers/${uid}`}
                  exit={{
                    delay: isCanvasAvailable ? 1 : 0,
                    length: 0,
                  }}
                  entry={{
                    delay: 0.1,
                    trigger: () => {
                      requestIdleCallback(() => {
                        setHideHeader(false)
                      })
                    },
                  }}
                  state={{ color: background_color }}
                  onClick={e => {
                    if (!shouldNavigate(e)) return

                    startPageTransition({ color: blob_color, color2: background_color, slow: true })
                    setHideHeader(true)
                  }}
                >
                  {index === 0 && (
                    <ViewportEnterEffect effect="drawLineHorizontal">
                      <div className={cn('line')} />
                    </ViewportEnterEffect>
                  )}

                  <div className={cn('row')}>
                    <div className={cn('jobContent')}>
                      <div className={cn('jobTitle')}>{RichText.render(jobTitle?.richText)}</div>

                      <span className={cn('jobLocation')}>{location}</span>
                    </div>

                    {ArrowIcon && <ArrowIcon className={cn('arrowIcon')} />}
                  </div>

                  <ViewportEnterEffect effect="drawLineHorizontal">
                    <div className={cn('line')} />
                  </ViewportEnterEffect>
                </TransitionLink>
              </li>
            )
          })}
        </ul>
      </Container>
    </section>
  )
}

Jobs.propTypes = {
  title: PropTypes.object,
  list: PropTypes.array,
}

export default Jobs
