import React from 'react'
import classNames from 'classnames/bind'
import PropTypes from 'prop-types'

import { Container } from 'components/ui/layout'
import SectionText from 'components/ui/section-text'

import * as s from './OurValues.module.css'

const cn = classNames.bind(s)

const OurValues = ({ title, text, columns }) => {
  return (
    <div className={cn('section')}>
      <Container className={cn('container')}>
        <SectionText title={title} text={text} />

        <ul className={cn('list')}>
          {columns?.map(({ values_column_title, values_column_text }, index) => (
            <li key={index} className={cn('item')}>
              <h3 className={cn('title')}>{values_column_title}</h3>

              <p className={cn('text')}>{values_column_text}</p>
            </li>
          ))}
        </ul>
      </Container>
    </div>
  )
}

OurValues.propTypes = {
  title: PropTypes.object,
  text: PropTypes.object,
  columns: PropTypes.array,
}

export default OurValues
