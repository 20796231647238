import React, { Fragment } from 'react'
import classNames from 'classnames/bind'
import PropTypes from 'prop-types'

import useUIContext from 'context/ui'

import { Container } from 'components/ui/layout'
import AspectRatio from 'components/ui/aspect-ratio'
import Image from 'components/ui/image'

import * as s from './Mosaic.module.css'

const cn = classNames.bind(s)

const types = {
  Vertical: 'vertical',
  Horizontal: 'horizontal',
  'Image 2 large': 'image2Large',
  'Image 2 small': 'image2Small',
}

const Mosaic = ({ images, mobileImages }) => {
  const isMobile = useUIContext(s => s.isMobile)

  const getImageRatio = image => {
    return image?.gatsbyImageData?.height / image?.gatsbyImageData?.width || 1
  }

  return (
    <section>
      <Container className={cn('container')}>
        <div className={cn('images')}>
          {isMobile
            ? mobileImages?.map(({ mosaic_image_mobile }, index) => {
                return (
                  <div className={cn('imageWrapper')} key={index}>
                    <AspectRatio ratio={getImageRatio(mosaic_image_mobile)} className={cn('image')}>
                      <Image
                        image={mosaic_image_mobile?.gatsbyImageData}
                        alt={mosaic_image_mobile.alt}
                        className={cn('image')}
                      />
                    </AspectRatio>
                  </div>
                )
              })
            : images?.map(({ mosaic_image, mosaic_image2, mosaic_image_type }, index) => {
                const type = types[mosaic_image_type]

                return (
                  <Fragment key={index}>
                    <div className={cn('imageWrapper', type)}>
                      <AspectRatio ratio={getImageRatio(mosaic_image)} className={cn('image', type)}>
                        <Image
                          image={mosaic_image?.gatsbyImageData}
                          alt={mosaic_image.alt}
                          className={cn('image', type)}
                        />
                      </AspectRatio>

                      {(type === 'image2Large' || type === 'image2Small') && (
                        <AspectRatio ratio={getImageRatio(mosaic_image2)} className={cn('image2', type)}>
                          <Image
                            image={mosaic_image2?.gatsbyImageData}
                            alt={mosaic_image2.alt}
                            className={cn('image2', type)}
                          />
                        </AspectRatio>
                      )}
                    </div>
                  </Fragment>
                )
              })}
        </div>
      </Container>
    </section>
  )
}

Mosaic.propTypes = {
  images: PropTypes.array,
  mobileImages: PropTypes.array,
}

export default Mosaic
