import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { linkResolver } from 'prismic'
import { prismicRepo } from '../../prismic-configuration'

import CareersPage from 'views/careers/CareersPage'

export const query = graphql`
  query CareersPageQuery {
    prismicCareers(uid: { eq: "careers" }, lang: { eq: "en-us" }) {
      _previewable
      uid
      data {
        page_meta_title
        page_meta_description
        page_meta_thumbnail {
          gatsbyImageData
        }

        hero_title {
          text
        }
        hero_description {
          text
        }
        cursor_text

        mosaic_images {
          mosaic_image {
            gatsbyImageData
          }
          mosaic_image2 {
            gatsbyImageData
          }
          mosaic_image_type
        }
        mosaic_images_mobile {
          mosaic_image_mobile {
            gatsbyImageData
          }
        }

        values_title {
          text
        }
        values_text {
          text
        }
        values_columns {
          values_column_title
          values_column_text
        }

        culture_title {
          text
        }
        culture_text {
          text
        }
        body {
          ...CareersBodyWhatSectionSliceFragment
        }

        jobs_title {
          text
        }
        jobs {
          job {
            document {
              ...JobFragment
            }
          }
        }
      }
    }
  }
`

export default withPrismicPreview(CareersPage, [
  {
    repositoryName: prismicRepo,
    linkResolver,
  },
])
